#cover-note {
  margin: 0 auto;
  max-width: 1000px;
}

.font-bold {
  font-weight: 600;
}

.font-bold-lg {
  font-size: 1rem;
  font-weight: 700;
}

.category {
  color: #000;
  font-weight: 600;
}

.surein-blue-text {
  color: #6a79fd;
}

.black-text {
  color: #000;
}

.unmet-dependency-alert {
  margin-bottom: 2rem;
}

.with-indent {
  margin-left: 24px;
}

.cover-note__header {
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
}

.cover-note__heading {
  text-align: center;
  font-size: 1.4rem;
}

.first-section {
  display: flex;
  margin-top: 1rem;
  padding: 1rem 0;
  justify-content: space-between;
  border-top: 2px solid black;
  border-bottom: 2px solid black;

  & p {
    color: #6a79fd;
  }

  &__first-column {
    color: #6a79fd;
    flex: 1;
  }

  &__second-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  &__third-column {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }
}

.requested-insurance {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  row-gap: 1rem;
}

.section-with-border {
  border: 2px solid black;
  padding: 1rem;
  margin-top: 2rem;
}

.second-section {
  & p {
    color: #6a79fd;
  }

  & .category {
    color: #000;
  }

  &__top-information {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  &__bottom-information {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    > div {
      flex: 1;
    }

    :nth-child(2) {
      flex: 1.5;
    }
  }
}

.third-section {
  display: flex;
  gap: 12rem;

  > div > p {
    margin-top: 0.5rem;
  }
}

.fourth-section {
  > div {
    display: flex;
    gap: 12rem;
  }

  > div > p {
    margin-top: 0.5rem;
  }
}

.fifth-section {
  > div {
    margin-top: 1rem;
  }

  &__bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 0.5rem;
    column-gap: 12rem;
  }
}

.sixth-section {
  margin-top: 0;

  .alt-grid {
    grid-template-columns: 2fr 2fr 0.5fr;
  }
}

.eight-section {
  .two-col-grid > .surein-blue-text {
    text-align: right;
  }

  .two-col-grid-alt > .surein-blue-text {
    text-align: right;
  }
}

.tenth-section {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  & .unstyled-list {
    padding-left: 0;
    list-style: none;
    line-height: 1.8rem;
  }
}

.fourteenth-section {
  & dt,
  li,
  p {
    margin-top: 1rem;
    margin-bottom: 0.8rem;
  }

  & dt {
    @extend .font-bold;
  }
}

.two-col-grid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 2rem;

  > p {
    margin-top: 0.5rem;
  }
}

.two-col-grid-alt {
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 2rem;

  > p {
    margin-top: 0.5rem;
  }
}

.three-col-grid {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 2rem;

  > p {
    margin-top: 0.5rem;
  }
}

.align-right {
  text-align: right;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-0 {
  margin-top: 0;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.text-end {
  text-align: end;
}

.italic {
  font-style: italic;
}

.font-bold {
  font-weight: bold;
}

.text-gray {
  color: gray;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.flex-1 {
  flex: 1 1;
}

.subsection {
  background-color: lightgray;
  font-weight: bold;
  padding: 0.25rem;
  margin-top: 2rem;
}

.data-row {
  display: flex;
  border-bottom: 1px solid lightgray;
  padding: 0.25rem;
  gap: 1rem;

  > div {
    flex: 1;
  }
}

.signature {
  margin-top: 10rem;
  border-top: 1px solid black;
  width: 60%;
}
