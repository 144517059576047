.filename-link {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.file-table {
  margin-top: 1rem;
}

.category-select {
  min-width: 200px;
}

.dragger-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ant-dropdown,
.ant-popover {
  z-index: 1000000;
}

.actions-icon {
  cursor: pointer;
}

.delete-text {
  color: red;
}

.filename-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: fit-content;
}

.file-action-dropdown {
  text-align: center;
}
