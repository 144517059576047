.yesNoRadio {
  display: flex;
  gap: 10px;

  .ant-radio-button-wrapper {
    border-radius: 8px;
  }

  .ant-radio-button-wrapper:not(:first-child):before {
    content: none;
  }

  .ant-radio-button-wrapper:last-child {
    border-width: 1.02px 1px 1px 1px;
  }

  .anticon-check-circle {
    color: #2cd3a1;
  }

  .anticon-close-circle {
    color: #ff5656;
  }

  .ant-radio-button-wrapper:last-child.ant-radio-button-wrapper-checked {
    background-color: rgba(255, 86, 86, 0.1);
    border-color: rgba(255, 86, 86, 0.1);
    color: #ff5656;
  }

  .ant-radio-button-wrapper:first-child.ant-radio-button-wrapper-checked {
    background-color: rgba(44, 211, 161, 0.05);
    border-color: rgba(44, 211, 161, 0.05);
    color: #2cd3a1;
  }
}

.hideCheckbox {
  > .ant-checkbox {
    display: none;
  }
}

.yes-no-radio {
  .radio-btn-yes,
  .radio-btn-no {
    border-radius: 8px !important;

    [role='img'] {
      margin-right: 0.25rem;
    }
  }

  .ant-radio-button-wrapper-checked:not(
      [class*=' ant-radio-button-wrapper-disabled']
    ).ant-radio-button-wrapper:first-child {
    border-color: unset;
  }

  .radio-btn-yes {
    &.ant-radio-button-wrapper-checked {
      background-color: rgba(44, 211, 161, 0.05);
      border-color: rgba(44, 211, 161, 0.05);
      color: #2cd3a1;
    }

    [role='img'] {
      color: #2cd3a1;
    }
  }

  .radio-btn-no {
    &.ant-radio-button-wrapper-checked {
      background-color: rgba(255, 86, 86, 0.1);
      border-color: rgba(255, 86, 86, 0.1);
      color: #ff5656;
    }

    [role='img'] {
      color: #ff5656;
    }
  }
}
