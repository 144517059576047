.dashboard-control-panel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  .dashboardSearch {
    width: 60%;
  }
}
.add-company-modal {
  border: 10px solid rgb(105, 187, 253);
  border-radius: 20px;
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label,
  .ant-form-vertical .ant-form-item-label {
    padding: 0;
  }
  .ant-form-item.form-no-margin {
    margin: 0;
  }
  .ant-form-item {
    margin: 0 0 10px;
  }
  .ant-modal-content {
    border-radius: 10px;
    .ant-input,
    .ant-input-number,
    .ant-select-selector,
    .ant-input-affix-wrapper {
      border: 1px solid rgb(238, 246, 255);
      background-color: rgb(238, 246, 255);
      width: 100%;
    }

    .ant-input-group-addon {
      border: 1px solid rgb(238, 246, 255);
      background-color: rgb(238, 246, 255);
    }
  }
  .ant-modal-header {
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0 0;
    .ant-modal-header {
      font-size: 22px;
    }
  }
  .ant-modal-footer {
    border: 0;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-btn-primary {
      opacity: 0.5;
      height: 43px;
      border-radius: 5px;
    }
    .ant-btn-default {
      height: 43px;
      border: none;
      background-color: rgb(249, 249, 255);
      border-radius: 5px;
    }
  }
}
.ant-modal {
  top: 50px;
  padding: 0;
}

.table-container {
  position: relative;
  .ant-pagination {
    position: absolute;
    bottom: 0px;
    right: 10px;
  }
}
