.pdf-document-wrapper {
  display: flex;
  justify-content: center;

  .react-pdf__Page {
    box-shadow: 0 0 4px 1px gray;
    margin-bottom: 1rem;
  }

  .page-skeleton {
    width: 1000px;
    height: 1414px;
    box-shadow: 0 0 4px 1px gray;
    padding: 100px 50px;
  }
}
