.attributeContainer {
  display: grid;
  grid-template-columns: 4fr 4fr 4fr 4fr 1fr;
  gap: 10px;
}

.validate-carrier-container {
  display: flex;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  background-color: rgb(241, 245, 253);

  .validate-carrier-info {
    display: flex;
    flex-direction: column;
  }
  .validate-carrier-carrierName {
    font-size: 16px;
    font-weight: 600;
  }
  .validate-carrier-productName {
    font-size: 14px;
    font-weight: 400;
  }
}
.ant-form-item.validate-carrier-runtime {
  margin-bottom: 0px;
}
.validate-sum-total-container {
  margin-top: 20px;
  .validate-sum-total-label {
    font-size: 20px;
  }
  .validate-sum-total-value {
    font-size: 20px;
    font-weight: 800;
  }
}
.validate-payment-name,
.validate-payment-iban {
  font-weight: 600;
}

.textarea-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}

.metadata-wrapper {
  display: flex;
  margin-top: -16px;
  gap: 10px;
  align-items: center;
}

.metadata-modal {
  display: flex;
  margin-top: 10px;
  gap: 20px;
}

.search-trigger {
  height: 3rem;
}

.ant-drawer-content-wrapper {
  margin-top: 120px;

  .ant-btn-primary {
    width: 100%;
  }
}

.mixed-businesses-calc-child {
  border: 1px solid lightgray;
  padding: 12px;
}

.mixed-businesses-calc-title {
  font-weight: 500;
  margin-bottom: 6px;
}

.details-dropdown {
  cursor: pointer;
}
