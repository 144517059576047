.attributeContainer {
  display: grid;
  grid-template-columns: 4fr 4fr 4fr 4fr 1fr;
  gap: 10px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 1rem;
}

.gridContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.yesNoContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .ant-form-item {
    flex-shrink: 0;
  }
}

.table-container {
  position: relative;

  .ant-pagination {
    position: absolute;
    bottom: 0px;
    right: 10px;
  }
}

.parameter-value-label {
  white-space: pre-line;
}
