.company-overview {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-direction: column;

  .company-overview-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .company-details-admin-select {
    width: 300px;
  }

  .company-overview-details {
    margin-top: 20px;
    width: 100%;
    box-shadow: rgb(21 0 85 / 5%) 0px 10px 10px 0px;
    background-color: white;
    border-radius: 20px 20px 0 0;
    overflow: auto;

    .company-overview-details-header {
      background-color: skyblue;
      height: 60px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px 0 20px;

      .user-details {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;

        > span {
          font-size: 18px;
          color: white;
        }

        > span:nth-child(3) {
          font-size: 14px;
          color: white;
        }
      }
    }

    .company-name {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      padding: 0 20px 0 20px;
      font-size: 16px;
      height: 60px;
      background-color: rgb(241, 245, 253);

      svg {
        cursor: pointer;
      }
    }

    .company-details {
      padding: 20px;

      .company-details-header {
        display: flex;
        justify-content: flex-end;

        .company-details-header-actions {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      .company-details-info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;

        .info-element {
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 10px;

          svg {
            cursor: pointer;
          }

          .label {
            color: grayscale($color: #2d0d0d);
            font-weight: lighter;
          }
        }
      }
    }

    .company-locations {
      padding: 20px;

      .company-locations-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgb(232, 232, 255);
        align-items: center;

        .company-locations-header-actions {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 5px;
        }
      }
    }

    .table-container {
      position: relative;

      .ant-pagination {
        position: absolute;
        bottom: 0px;
        right: 10px;
      }
    }

    .company-payments {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      padding: 10px 0;

      .company-payment-card {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        background-color: rgb(241, 245, 253);
        color: gray;

        div {
          display: flex;
          gap: 10px;
        }
      }
    }

    .company-users {
      padding: 20px;

      .company-users-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgb(232, 232, 255);

        .company-users-header-actions {
          display: flex;
          gap: 10px;
          padding: 5px;
        }
      }

      .company-users-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        padding: 10px 0;

        .company-user {
          width: 100%;
          display: flex;
          flex-direction: column;
          background-color: rgb(241, 245, 253);
          border-radius: 10px;
          padding: 10px;
          gap: 6px;

          .company-user-name {
            font-size: 14px;
            font-weight: 500;
          }

          .company-user-name-highlight {
            color: skyblue;
          }

          .company-user-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .company-user-header-actions {
              display: flex;
              gap: 10px;

              svg {
                cursor: pointer;
              }
            }
          }

          .company-user-detail {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            font-size: 12px;

            .label {
              color: grayscale($color: #2d0d0d);
              font-weight: lighter;
            }
          }
        }
      }
    }
  }
}

.insurance-contract-check {
  text-align: center;
}

.banking-form-wrapper {
  width: 100%;
}
