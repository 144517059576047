@keyframes loader {
  50% {
    filter: brightness(300%);
    animation-timing-function: ease-in;
  }
  100% {
    filter: brightness(100%);
    animation-timing-function: ease-in;
  }
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  position: fixed;
  left: 0;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);

  img {
    width: 100px;
    animation: loading 1.5s infinite;
  }

  &.loader-fill {
    height: 100%;
    position: absolute;

    img {
      width: 50px;
    }
  }
}
