.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 20px 40px;
  background-color: white;
  box-shadow: rgb(21 0 85 / 5%) 0px 10px 10px 0px;
  z-index: 100000;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
  }
}

.nav-link {
  color: #000;
  padding-bottom: 0.25rem;

  &.active {
    border-bottom: 2px solid #1890ff;
  }
}

.sub-nav-link {
  &.active {
    color: #1890ff;
  }
}
