.add-insurance-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.add-insurance-row {
  display: flex;
  gap: 10px;
}

.net-price-yearly {
  background-color: lightgray;
  padding: 4px 10px;
  cursor: not-allowed;
}

.add-insurance-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-insurance-form-item {
  width: 100%;
}

.add-insurance-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.essentialRatio {
  padding: 4px;
  border-radius: 4px;
  margin-left: 10px;

  &.essentialRatioDanger {
    color: #ff5656;
    background: rgba(255, 86, 86, 0.1);
  }

  &.essentialRatioSafe {
    color: #2cd3a1;
    background: rgba(44, 211, 161, 0.05);
  }

  &.essentialRatioWarn {
    color: #ff8c22;
    background-color: #fac08b;
  }
}

.insurance-container {
  padding: 20px;
}

.insurance-column {
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  box-shadow: rgb(21 0 85 / 5%) 0px 10px 10px 0px;
  border: 1px solid lightgray;
}

.company-details-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  .info-element {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
      cursor: pointer;
    }
    .label {
      color: grayscale($color: #2d0d0d);
      font-weight: lighter;
    }
  }
}

.insurance-card-body {
  display: flex;
  justify-content: space-between;
}

.insurance-card {
  padding: 10px;
  border: 1px solid lightblue;
  border-radius: 6px;
  background-color: rgba(52, 97, 255, 0.1);

  &.insurance-card-danger {
    border-color: #ff5656;
    background-color: rgba(255, 86, 86, 0.1);
  }
}

.insurance-card-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.insurance-card-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.insurance-card-actions-top {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.insurance-card-actions-row {
  display: flex;
  gap: 10px;
  > * {
    width: 100%;
  }
}

.accepted-recommendation {
  display: block;
  text-align: center;
  padding: 2rem 1rem;
  color: #000;
  background-color: #d2f0d5;
  border: 1px solid #d9d9d9;
  margin-top: 2rem;
  &:hover {
    cursor: pointer;
    background-color: #d2f0d590;
  }
}

.recommendation-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

@keyframes select {
  from {
    opacity: 0;
  }
}

.recommendation-card {
  position: relative;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  z-index: 1;

  &.recommendation-card-sent {
    border-color: #2cd3a1;
    background-color: #2cd3a1;
    &:not(.recommendation-card-disabled) {
      color: white;
    }
  }

  &.recommendation-card-rejected {
    border-color: #ff5656;
    background-color: rgba(255, 86, 86, 0.5);
  }

  &.recommendation-card-waiting {
    border-color: #fac08b;
    background-color: #fac08b;
  }

  &.recommendation-card-disabled {
    cursor: not-allowed;
    background-color: #f5f5f5 !important;
  }

  .anticon-close-circle,
  .anticon-check-circle {
    animation: select 0.3s;
    position: absolute;
    z-index: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.4;
    svg {
      font-size: 42px;
    }
  }

  .anticon-close-circle {
    color: #ff5656;
  }

  .anticon-check-circle {
    color: #2cd3a1;
  }
}
.add-company-modal {
  .ant-radio-group {
    width: 100%;
  }
  .ant-modal-title {
    font-size: 24px;
  }
  .assesmentForm {
    .assesment-modal-scopesCheckboxes-container {
      margin: 10px;
      width: 400px;
    }
    .assesmentForm-dateOrNext-label {
      font-size: 14px;
      font-weight: bold;
    }
    .ant-radio-button-wrapper {
      display: block;
    }
    .ant-col.ant-form-item-label {
      font-weight: bold;
      font-size: 26px;
      white-space: pre-line;
    }
    .assesmentForm-dateOrNext {
      display: flex;
      gap: 10px;
      align-items: center;
    }
    .ant-form-item-control-input {
      min-height: unset;
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0px;
    }
    .ant-form-item {
      width: 100%;
    }
    .ant-form-item {
      margin: 0;
    }
    .scopesCheckboxes {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 10px;
    }
    .radioBuildingType {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
.historyCardContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .historyCard {
    border: 1px solid rgb(229, 229, 255);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgb(250, 250, 255);
    padding: 10px;
    gap: 10px;
    border-radius: 10px;
    ul {
      margin-top: 10px;
    }
    .historyCard-date {
      font-size: 10px;
      font-weight: 300;
      color: rgb(98, 98, 113);
    }
    .historyCard-message {
      font-size: 12px;
      font-weight: 500;
      color: rgb(43, 42, 52);
      line-height: 1.5;
    }
    .spacer > span {
      margin-right: 4px;
    }
    .historyCard-message.document {
      font-size: 12px;
      font-weight: 500;
      color: blue;
      line-height: 1.5;
      cursor: pointer;
    }
  }
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item {
  width: 100%;
}

.ant-checkbox + span {
  width: 100%;
}

.ant-collapse {
  .ant-checkbox-wrapper {
    padding: 6px;
    background-color: #f5f5f5;
    border: 1px solid #d9d9d9;
    margin-bottom: 8px;

    & > .ant-checkbox {
      display: none;
    }
  }
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    background-color: #1890ff;
    border-color: #1890ff;
    color: white;
  }
  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    background-color: #f6f6f6;
  }
  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled.ant-checkbox-wrapper-checked {
    border-color: #d9d9d9;
    background-color: #e6e6e6;
  }
}

.next-payment-date {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.yearly-check-change-label {
  > span:not(:first-child) {
    padding-left: 4px;
  }
}

.required-files {
  margin-bottom: 0;

  > .ant-form-item-row > .ant-form-item-control {
    flex-direction: row;
    align-items: baseline;
  }
}
